var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "grid-list-l": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v("\n          Groups\n          "),
                      _c("v-spacer"),
                      _c("v-text-field", {
                        attrs: {
                          label: "Search",
                          "prepend-icon": "search",
                          "single-line": "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm.hasEditAccess
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary ml-4",
                                dark: "",
                                small: "",
                                fab: ""
                              },
                              on: { click: _vm.createGroup }
                            },
                            [_c("v-icon", [_vm._v("add")])],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.groupsModel.groupsHeaders,
                      items: _vm.groupsModel.groups,
                      loading: _vm.isLoading(),
                      "rows-per-page-items": [
                        10,
                        50,
                        { text: "All", value: -1 }
                      ],
                      search: _vm.search
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "progress",
                        fn: function() {
                          return [
                            _c("v-progress-linear", {
                              attrs: {
                                height: "2",
                                color: "blue",
                                indeterminate: true
                              }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c(
                              "td",
                              { staticClass: "justify-center layout px-0" },
                              [
                                _vm.hasEditAccess
                                  ? _c(
                                      "v-menu",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      { attrs: { icon: "" } },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticStyle: {
                                                            color: "grey",
                                                            "line-height": "0px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-dots-vertical"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c(
                                              "v-list-tile",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editGroup(
                                                      props.item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-list-tile-title", [
                                                  _vm._v("Edit")
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-tile",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteGroup(
                                                      props.item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-list-tile-title", [
                                                  _vm._v("Delete")
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("td", [_vm._v(_vm._s(props.item.name))]),
                            _c("td", [_vm._v(_vm._s(props.item.adGroup))])
                          ]
                        }
                      },
                      {
                        key: "no-results",
                        fn: function() {
                          return [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  value: true,
                                  color: "error",
                                  icon: "warning"
                                }
                              },
                              [
                                _vm._v(
                                  'Your search for "' +
                                    _vm._s(_vm.search) +
                                    '" found no results.'
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("GroupsModal", {
            on: { "save-changes": _vm.saveGroup },
            model: {
              value: _vm.showGroupsModal,
              callback: function($$v) {
                _vm.showGroupsModal = $$v
              },
              expression: "showGroupsModal"
            }
          }),
          _c("modal-confirmation", {
            attrs: { message: "Are you sure you want to delete this group?" },
            on: { "confirmation-click": _vm.onDeleteConfirm },
            model: {
              value: _vm.showDeleteConfirmation,
              callback: function($$v) {
                _vm.showDeleteConfirmation = $$v
              },
              expression: "showDeleteConfirmation"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }