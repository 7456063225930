var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-form",
    {
      attrs: { title: _vm.currentGroup.formTitle, "max-width": "600" },
      on: { "save-click": _vm.saveGroup, "esc-press": _vm.onEscPressed },
      model: {
        value: _vm.showGroupsModal,
        callback: function($$v) {
          _vm.showGroupsModal = $$v
        },
        expression: "showGroupsModal"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "groupsForm",
          attrs: { "lazy-validation": true },
          model: {
            value: _vm.currentGroup.isModelValid,
            callback: function($$v) {
              _vm.$set(_vm.currentGroup, "isModelValid", $$v)
            },
            expression: "currentGroup.isModelValid"
          }
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Name",
                          required: "",
                          rules: _vm.currentGroup.isRequiredRules
                        },
                        model: {
                          value: _vm.currentGroup.groups.name,
                          callback: function($$v) {
                            _vm.$set(_vm.currentGroup.groups, "name", $$v)
                          },
                          expression: "currentGroup.groups.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "AD Group",
                          required: "",
                          rules: _vm.currentGroup.isRequiredRules
                        },
                        model: {
                          value: _vm.currentGroup.groups.adGroup,
                          callback: function($$v) {
                            _vm.$set(_vm.currentGroup.groups, "adGroup", $$v)
                          },
                          expression: "currentGroup.groups.adGroup"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list",
                {
                  staticStyle: { margin: "0 -16px" },
                  attrs: { subheader: "" }
                },
                [
                  _c("v-subheader", [_vm._v("Permissions")]),
                  _c(
                    "v-list-tile",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setAllChecked()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "success",
                              indeterminate: _vm.isIndeterminateState
                            },
                            on: {
                              change: function($event) {
                                $event.stopPropagation()
                                return _vm.setAllChecked()
                              }
                            },
                            model: {
                              value: _vm.isCheckedBox,
                              callback: function($$v) {
                                _vm.isCheckedBox = $$v
                              },
                              expression: "isCheckedBox"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Select All Permissions")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _vm._l(_vm.currentGroup.permissionsSelectList, function(
                    permission,
                    index
                  ) {
                    return _c(
                      "v-list-tile",
                      {
                        key: index,
                        on: {
                          click: function($event) {
                            return _vm.selectPermission(permission)
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-tile-action",
                          [
                            _c("v-checkbox", {
                              attrs: {
                                color: "success",
                                value: permission.value
                              },
                              on: {
                                change: function($event) {
                                  return _vm.selectPermission(permission)
                                }
                              },
                              model: {
                                value: _vm.currentGroup.selectedPermissions,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.currentGroup,
                                    "selectedPermissions",
                                    $$v
                                  )
                                },
                                expression: "currentGroup.selectedPermissions"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile-content",
                          [
                            _c("v-list-tile-title", [
                              _vm._v(_vm._s(permission.text))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }