import { render, staticRenderFns } from "./GroupsModal.vue?vue&type=template&id=b2ad4c10&"
import script from "./GroupsModal.vue?vue&type=script&lang=ts&"
export * from "./GroupsModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VContainer,VDivider,VFlex,VForm,VLayout,VList,VListTile,VListTileAction,VListTileContent,VListTileTitle,VSubheader,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\WlpScheduler\\front-end\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b2ad4c10')) {
      api.createRecord('b2ad4c10', component.options)
    } else {
      api.reload('b2ad4c10', component.options)
    }
    module.hot.accept("./GroupsModal.vue?vue&type=template&id=b2ad4c10&", function () {
      api.rerender('b2ad4c10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/groups/GroupsModal.vue"
export default component.exports